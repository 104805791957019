// @flow
import types from './signatureConstants';
import { postAsync, getAsync, patchAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const postSignature = (signatureData) => async dispatch => {
    dispatch({
        type: types.POST_SIGNATURE
    });

    const response = await postAsync('/signatures', signatureData);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_SIGNATURE_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.POST_SIGNATURE_FAILED,
            payload: response
        });
    }

    return response;
};

export const getSignature = (id) => async dispatch => {
    dispatch({
        type: types.GET_SIGNATURE
    });

    const response = await getAsync(`/signatures/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_SIGNATURE_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.GET_SIGNATURE_FAILED,
            payload: response
        });
    }

    return response;
};

export const verifySignature = (id, signatureImage) => async dispatch => {
    dispatch({
        type: types.VERIFY_SIGNATURE
    });

    const response = await postAsync(`/signatures/${id}/verify`, { signatureImage });

    if (response.isSuccess) {
        dispatch({
            type: types.VERIFY_SIGNATURE_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.VERIFY_SIGNATURE_FAILED,
            payload: response
        });
    }

    return response;
};

export const patchSignature = (id, data) => async dispatch => {
    dispatch({
        type: types.PATCH_SIGNATURE
    });

    const response = await patchAsync(`/signatures/${id}`, data);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_SIGNATURE_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.PATCH_SIGNATURE_FAILED,
            payload: response
        });
    }

    return response;
};