import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Button, Row, Col } from 'reactstrap';
import { enumUserType, KTCard, KTCardBody, Loader, toAbsoluteUrl } from '../../../_metronic/helpers'
import { toast } from 'react-toastify';
import IBAN from 'iban'
import { getCities, getCountries, getPhoneCodes } from '../locations/locationActions';
import { Audit } from '../../../_metronic/helpers/components/Audit';
import Multiselect from 'multiselect-react-dropdown';

import { clearState, getUser, postUser, patchUser, uploadAvatar, getContact, postContact, putContact, getPolicies, getPersonalDiscount, postPersonalDiscount, patchPersonalDiscount } from './userActions';
import UserPersonalDiscount from './userPersonalDiscount';
import Resizer from "react-image-file-resizer";
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';
import { enumPolicies } from '../../../_metronic/helpers/enumPolicies';

const User = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    //inputs from redux
    const {
        user,
        newAvatar,
        contact,
        personalDiscount,
        countryList,
        cityList,
        phoneCodeList,
        policies,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getUser,
        postUser,
        patchUser,
        uploadAvatar,
        getContact,
        postContact,
        putContact,
        getCountries,
        getCities,
        getPhoneCodes,
        getPersonalDiscount,
        postPersonalDiscount,
        patchPersonalDiscount,
        getPolicies
    } = props;

    const [avatar, setAvatar] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(params.id > 0 ? false : true);
    const policiesDropdown = useRef();

    useEffect(() => {
        if (params.id > 0) {
            getUser(params.id);
            getContact(params.id);
        }

        getCountries();
        getPhoneCodes();
        getPolicies();

        return () => {
            clearState()
        }

    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    useEffect(() => {

        if (params.id === '0' && user && user.id > 0) {
            navigate(`/user-management/users/${user.id}`);
        }

        setAvatar(user && user.avatarLink);

    }, [user]);

    useEffect(() => {

        if (contact && contact.countryId > 0)
            getCities(contact.countryId)

    }, [contact]);

    const getSelectedItems = (list, ids) => {
        if (!list || !ids) return [];

        var items = list.filter((item) => {
            return ids.find(s => s == item.id);
        })

        return items;
    }

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'USER.MANAGEMENT.TITLE' }),
            path: '/user-management/users',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const handleValidSubmit = (event, values) => {
        if (newAvatar && avatar != user.avatarLink) {
            values = {
                ...values,
                avatarLink: newAvatar
            };
        }

        values = {
            ...values,
            policyIds: policiesDropdown.current.getSelectedItems().map(p => p.id)
        };

        if (user.id > 0) {
            patchUser({ ...values })
        } else {
            postUser({ ...values })
        }
    }

    const handleValidContactSubmit = (event, values) => {

        if (contact && contact.id > 0) {
            putContact({ ...values })
        } else {
            postContact({ ...values })
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const onAvatarChange = async (e) => {

        var f = e.target.files[0];

        var image = await resizeFile(f);
        image.name = f.name;

        var file = {
            data: image,
            id: user.id,
            name: image.name,
            extension: image.type.split('/')[1],
            actionType: 1
        }

        loadImage(f);

        uploadAvatar(file);
    }

    const loadImage = file => {

        var reader = new FileReader();
        reader.onloadend = function () {
            setAvatar(reader.result)
        }
        reader.readAsDataURL(file);

    };

    const cancelAvatar = () => {
        setAvatar(user.avatarLink)
    }

    const onPasswordChange = (e) => {
        if (!params.id > 0) return;

        setPasswordChanged(e.target.value.length >= 1)
    }

    const onCountryChange = (value) => {
        if (value > 0)
            getCities(value)
    }

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const emailValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        email: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.EMAIL_INVALID' })
        }
    }

    const passwordValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        minLength: {
            value: 5,
            errorMessage: intl.formatMessage({ id: "VALIDATION.USER.PASSWORD" })
        }
    }

    const phoneValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const ibanValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        validateIban: function validateIban(value, context, constraint = {}) {
            if (!value) return true;
            return IBAN.isValid(value) || intl.formatMessage({ id: 'VALIDATION.IBAN_INVALID' })
        },
        maxLength: {
            value: 30,
            errorMessage: intl.formatMessage({ id: 'VALIDATION.IBAN_INVALID' })
        }
    }

    const kvkNumberValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        pattern: {
            value: "^[0-9]{8}$",
            errorMessage: intl.formatMessage({ id: 'VALIDATION.KVK_INVALID' })
        },
        maxLength: {
            value: 8,
            errorMessage: intl.formatMessage({ id: 'VALIDATION.KVK_INVALID' })
        }
    }

    const btwNumberValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        pattern: {
            value: "^[A-Za-z]{2}[0-9]{9}[A-Za-z][0-9]{2}$",
            errorMessage: intl.formatMessage({ id: 'VALIDATION.BTW_INVALID' })
        },
        maxLength: {
            value: 14,
            errorMessage: intl.formatMessage({ id: 'VALIDATION.BTW_INVALID' })
        }
    }

    const renderUserForm = () => {
        return (
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <h3 >{intl.formatMessage({ id: 'USER.TITLE' })}</h3>
                    </div>
                    <div className="card-toolbar">
                        <Audit table="User" identifier={user.id} />
                    </div>
                </div>
                <KTCardBody>
                    <AvForm key={1} onValidSubmit={handleValidSubmit}>

                        <div className='fv-row mb-7'>
                            <label className='d-block fw-bold fs-6 mb-5'>{intl.formatMessage({ id: 'USER.AVATAR' })}</label>

                            <div
                                className='image-input image-input-outline'
                                data-kt-image-input='true'
                                style={{ backgroundImage: `url('${toAbsoluteUrl('/media/svg/avatars/blank.svg')}')` }}
                            >
                                <div
                                    className='image-input-wrapper w-125px h-125px'
                                    style={{ backgroundImage: `url('${avatar}')` }}
                                ></div>

                                <label
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='change'
                                    data-bs-toggle='tooltip'
                                    title={intl.formatMessage({ id: 'USER.CHANGE_AVATAR' })}
                                >
                                    <i className='bi bi-pencil-fill fs-7'></i>

                                    <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={(e) => onAvatarChange(e)} />
                                    <input type='hidden' name='avatar_remove' />
                                </label>
                                <span
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='cancel'
                                    data-bs-toggle='tooltip'
                                    title={intl.formatMessage({ id: 'USER.CANCEL_AVATAR' })}
                                >
                                    <i className='bi bi-x fs-2'></i>
                                </span>
                                <span
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='remove'
                                    data-bs-toggle='tooltip'
                                    title={intl.formatMessage({ id: 'USER.REMOVE_AVATAR' })}
                                    onClick={() => cancelAvatar()}
                                >
                                    <i className='bi bi-x fs-2'></i>
                                </span>
                            </div>
                            <div className='form-text'> {intl.formatMessage({ id: 'USER.AVATAR_FILE_TYPES' })} png, jpg, jpeg.</div>
                        </div>

                        <Row>
                            <Col>
                                <AvField name="name" label={intl.formatMessage({ id: 'USER.NAME' })} type="text" value={user && user.name} validate={validation} />
                            </Col>
                            <Col>
                                <AvField name="surname" label={intl.formatMessage({ id: 'USER.SURNAME' })} type="text" value={user && user.surname} validate={validation} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField name="email" label={intl.formatMessage({ id: 'USER.EMAIL' })} type="text" value={user && user.email} validate={emailValidation} />
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <AvField name="phoneCode" label={intl.formatMessage({ id: 'USER.PHONE_CODE' })} type="select" value={user && user.phoneCode} validate={validation}>
                                            <option value=''>Select a Code</option>
                                            {phoneCodeList && phoneCodeList.map((item, index) => {
                                                return (
                                                    <option key={`code_${item.id}`} value={item.phoneCode}>{item.phoneCode}</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                    <Col>
                                        <AvField name="phone" label={intl.formatMessage({ id: 'USER.PHONE' })} type="text" value={user && user.phone} validate={phoneValidation} maxLength={10} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField name="title" label={intl.formatMessage({ id: 'USER.TITLE_NAME' })} type="text" value={user && user.title} validate={validation} />
                            </Col>
                            <Col>
                                <AvField name="password" label={intl.formatMessage({ id: 'USER.PASSWORD' })} type="text" placeholder={user && user.id > 0 ? '******' : ''} value={user && user.password} validate={passwordChanged ? passwordValidation : {}} onChange={onPasswordChange} />
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <AvField type="select" name="type" label={intl.formatMessage({ id: 'USER.ROLE' })} validate={validation} value={user && user.type}>
                                    <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                    <option value='1'>{intl.formatMessage({ id: 'USER.TYPES.ADMIN' })}</option>
                                    <option value='2'>{intl.formatMessage({ id: 'USER.TYPES.WORKER' })}</option>
                                    <option value='3'>{intl.formatMessage({ id: 'USER.TYPES.DEALER' })}</option>
                                </AvField>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='mb-3 mt-3'>
                                    <label htmlFor="isActive" className="form-label">{intl.formatMessage({ id: 'USER.ACTIVE.ENABLED' })}</label>
                                    <AvField type="checkbox" name="isActive" checked={user && user.isActive} />
                                </div>
                            </Col>
                            <Col>
                                <div className='mb-3 mt-3'>
                                    <label htmlFor="isApprove" className="form-label">{intl.formatMessage({ id: 'USER.APPROVE' })}</label>
                                    <AvField type="checkbox" name="isApprove" checked={user && user.isApprove} />
                                </div>
                            </Col>
                            <Col>
                                <div className='mb-3 mt-3'>
                                    <label htmlFor="emailNotification" className="form-label">{intl.formatMessage({ id: 'USER.EMAIL_NOTIFICATION' })}</label>
                                    <AvField type="checkbox" name="emailNotification" checked={user && user.emailNotification} />
                                </div>
                            </Col>
                            <Col>
                                <div className='mb-3 mt-3'>
                                    <label htmlFor="smsNotification" className="form-label">{intl.formatMessage({ id: 'USER.SMS_NOTIFICATION' })}</label>
                                    <AvField type="checkbox" name="smsNotification" checked={user && user.smsNotification} />
                                </div>
                            </Col>
                            <Col>
                                <div className='mb-3 mt-3'>
                                    <label htmlFor="pushNotification" className="form-label">{intl.formatMessage({ id: 'USER.PUSH_NOTIFICATION' })}</label>
                                    <AvField type="checkbox" name="pushNotification" checked={user && user.pushNotification} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='fv-row mb-8'>
                                    <label className='form-label fs-6'>{intl.formatMessage({ id: 'USER.FIELD.POLICIES' })}</label>
                                    <Multiselect name="policyIds" ref={policiesDropdown}
                                        options={policies}
                                        selectedValues={getSelectedItems(policies, user.policyIds)}
                                        displayValue="name"
                                        isObject={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <AvField type="hidden" name="id" value={user && user.id} />
                        <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                    </AvForm>
                </KTCardBody>
            </KTCard >
        )
    }

    const renderContactForm = () => {

        if (user && user.id > 0) {
            return (
                <KTCard className='mt-10'>

                    <div className="card-header">
                        <h3 className="card-title">{intl.formatMessage({ id: 'CONTACT.TITLE' })}</h3>
                    </div>

                    <KTCardBody>

                        <AvForm key={1} onValidSubmit={handleValidContactSubmit}>

                            <Row>
                                <Col>
                                    <AvField name="company" label={intl.formatMessage({ id: 'CONTACT.COMPANY' })} type="text" value={contact && contact.company} validate={validation} />
                                </Col>
                                <Col>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvField name="street" label={intl.formatMessage({ id: 'CONTACT.STREET' })} type="text" value={contact && contact.street} validate={validation} />
                                </Col>
                                <Col>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvField name="building" label={intl.formatMessage({ id: 'CONTACT.BUILDING' })} type="text" value={contact && contact.building} validate={validation} />
                                </Col>
                                <Col>
                                    <AvField name="zipCode" label={intl.formatMessage({ id: 'CONTACT.ZIPCODE' })} type="text" value={contact && contact.zipCode} validate={validation} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <AvField name="cityId" label={intl.formatMessage({ id: 'CONTACT.CITY' })} type="select" value={contact && contact.cityId} validate={validation} >
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        {cityList && cityList.map((item, index) => {
                                            return (
                                                <option key={`city_${item.id}`} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </AvField>
                                </Col>
                                <Col>
                                    <AvField name="countryId" label={intl.formatMessage({ id: 'CONTACT.COUNTRY' })} type="select" value={contact && contact.countryId} validate={validation} onChange={(e) => { onCountryChange(e.target.value) }}>
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        {countryList && countryList.map((item, index) => {
                                            return (
                                                <option key={`country_${item.id}`} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </AvField>
                                </Col>
                            </Row>

                            <Row>
                                <Col>

                                    <AvField name="kvkNumber" label={intl.formatMessage({ id: 'CONTACT.KVK' })} type="text" value={contact && contact.kvkNumber} validate={kvkNumberValidation} />
                                </Col>
                                <Col>
                                    <AvField name="iban" label={intl.formatMessage({ id: 'CONTACT.IBAN' })} type="text" value={contact && contact.iban} validate={ibanValidation} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <AvField name="btwNumber" label={intl.formatMessage({ id: 'CONTACT.BTW' })} type="text" value={contact && contact.btwNumber} validate={btwNumberValidation} />
                                </Col>
                                <Col md={3}>
                                    <AvField name="vat" label={intl.formatMessage({ id: 'CONTACT.VAT' })} type="number" value={contact && contact.vat} validate={validation} />
                                </Col>
                                <Col md={3}>
                                    <AvField name="currency" label={intl.formatMessage({ id: 'CONTACT.CURRENCY' })} type="select" value={contact && contact.currency} validate={validation}>
                                        <option value='EUR'>Euro</option>
                                        <option value='USD'>US Dollar</option>
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvField name="email" label={intl.formatMessage({ id: 'CONTACT.EMAIL' })} type="text" value={contact && contact.email} validate={emailValidation} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <AvField name="phoneCode" label={intl.formatMessage({ id: 'CONTACT.PHONE_CODE' })} type="select" value={contact && contact.phoneCode} validate={validation}>
                                                <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                {phoneCodeList && phoneCodeList.map((item, index) => {
                                                    return (
                                                        <option key={`code_${item.id}`} value={item.phoneCode}>{item.phoneCode}</option>
                                                    )
                                                })}
                                            </AvField>
                                        </Col>
                                        <Col>
                                            <AvField name="phone" label={intl.formatMessage({ id: 'CONTACT.PHONE' })} type="text" value={contact && contact.phone} validate={validation} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>


                            <AvField type="hidden" name="userId" value={user && user.id} />
                            <AvField type="hidden" name="id" value={contact && contact.id} />

                            <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                        </AvForm>
                    </KTCardBody>
                </KTCard >
            )
        }

        return (<></>)
    }

    const renderPersonalDiscountForm = () => {
        if (user && user.id > 0) {
            return (
                <UserPersonalDiscount 
                    user={user} 
                    personalDiscount={personalDiscount}
                    getPersonalDiscount={getPersonalDiscount}
                    postPersonalDiscount={postPersonalDiscount}
                    patchPersonalDiscount={patchPersonalDiscount}
                />
            )
        }
        return (<></>)
    }

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: params.id > 0 ? 'USER.EDIT.BREADCRUMB' : 'USER.CREATE.BREADCRUMB' })}</PageTitle>

            {renderUserForm()}

            {renderContactForm()}

            { user && user.type === enumUserType.Dealer && (
                <PolicyChecker policies={[enumPolicies.DiscountEdit, enumPolicies.DiscountWrite]}>
                    {renderPersonalDiscountForm()}
                </PolicyChecker>
            )}

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.User.user,
        newAvatar: state.User.newAvatar,
        contact: state.User.contact,
        personalDiscount: state.User.personalDiscount,
        countryList: state.Location.countryList,
        cityList: state.Location.cityList,
        phoneCodeList: state.Location.phoneCodeList,
        policies: state.User.policies,
        loading: state.User.loading,
        success: state.User.success
    };
};

const mapActionsToProps = {
    clearState, getUser, postUser, patchUser, uploadAvatar, getContact, postContact, putContact, getCountries, getCities, getPhoneCodes, getPolicies, getPersonalDiscount, postPersonalDiscount, patchPersonalDiscount
}

export default connect(mapStateToProps, mapActionsToProps)(User)