import { useRef, useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { getSignature, patchSignature, postSignature, clearState } from "./signatureActions";

export const DigitalSignature = ({ 
    intl,
    signatureId, 
    canEdit, 
    customerId, 
    userId,
    onSignatureIdChange
}) => {
    const [isEditing, setIsEditing] = useState(!signatureId); 
    const [signature, setSignature] = useState(null);
    const signatureRef = useRef(null);
    const dispatch = useDispatch();
    const signatureState = useSelector(state => state.Signature || {});

    useEffect(() => {
        if (signatureId) {
            dispatch(getSignature(signatureId));
        } else {
            dispatch(clearState());
        }
    }, [signatureId, dispatch]);

    useEffect(() => {
        if (signatureState && signatureState.signature && signatureState.signature.signatureImageData) {
            setSignature(`data:image/png;base64,${signatureState.signature.signatureImageData}`);
            setIsEditing(false);
        } else {
            setSignature(null);
            setIsEditing(true);
        }
    }, [signatureState]);

    const handleClearSignature = () => {
        if (!signatureRef.current) return;
        
        signatureRef.current.clear();
        setSignature(null);
        setIsEditing(true);
        dispatch(clearState());
    };

    const saveSignature = () => {
        if (!signatureRef.current || signatureRef.current.isEmpty()) {
            alert(intl.formatMessage({id: "SIGNATURE.ERROR.EMPTY"}));
            return;
        }

        const signatureData = signatureRef.current.toDataURL("image/png");
        const signatureBytes = atob(signatureData.split(',')[1]).split('').map(char => char.charCodeAt(0));

        if (signatureId) {
            dispatch(patchSignature(signatureId, {
                signatureImageData: {
                    set: true,
                    value: convertToBase64(signatureBytes)
                }
            })).then(response => {
                if (response?.isSuccess) {
                    setSignature(signatureData);
                    setIsEditing(false);
                }
            }).catch(error => {
                console.error("Failed to update signature:", error);
                alert(intl.formatMessage({id: "SIGNATURE.ERROR.SAVE"}));
            });
        } else {
            dispatch(postSignature({
                customerId: customerId || null,
                userId: userId || null,
                signatureImageData: convertToBase64(signatureBytes),
                ipAddress: 'ipAddr',
                deviceInfo: 'deviceInfo'
            })).then(response => {
                if (response?.isSuccess) {
                    setSignature(signatureData);
                    setIsEditing(false);
                    onSignatureIdChange(response.data.id);
                }
            }).catch(error => {
                console.error("Failed to create signature:", error);
                alert(intl.formatMessage({id: "SIGNATURE.ERROR.SAVE"}));
            });
        }
    };

    return (
        <div className="card card-flush">
            <div className="card-body text-end">
                <div className="mb-5 d-flex justify-content-end">
                    <i className="ki-duotone ki-pencil fs-2x text-gray-800 me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                    <div className="w-100" style={{ maxWidth: '400px' }}>
                        {isEditing ? (
                            <SignaturePad 
                                ref={signatureRef} 
                                canvasProps={{ 
                                    className: "signature-pad bg-light rounded w-100",
                                    style: { 
                                        height: '150px',
                                        minWidth: '250px',
                                        maxWidth: '100%'
                                    }
                                }} 
                            />
                        ) : (
                            signature && (
                                <img 
                                    src={signature} 
                                    alt={intl.formatMessage({id: "SIGNATURE.TITLE"})} 
                                    className="img-fluid rounded border border-gray-300" 
                                    style={{ 
                                        height: '150px',
                                        minWidth: '250px',
                                        maxWidth: '100%'
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>

                {canEdit && (
                    <div className="d-flex justify-content-end gap-3 flex-wrap">
                        {isEditing ? (
                            <>
                                <button 
                                    onClick={handleClearSignature} 
                                    className="btn btn-light btn-active-light-primary"
                                >
                                    {intl.formatMessage({id: "SIGNATURE.CLEAR"})}
                                </button>
                                <button 
                                    onClick={saveSignature} 
                                    className="btn btn-primary"
                                >
                                    {intl.formatMessage({id: "SIGNATURE.SAVE"})}
                                </button>
                            </>
                        ) : (
                            <button 
                                onClick={() => setIsEditing(true)} 
                                className="btn btn-light btn-active-light-primary"
                            >
                                {intl.formatMessage({id: "SIGNATURE.EDIT"})}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const convertToBase64 = (bytes) => {
    return bytes ? btoa(String.fromCharCode(...bytes)) : '';
};

export default DigitalSignature;