import React from "react";

export {};

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">Effective Date: March 18, 2025</p>

      <p className="mb-4">
        {process.env.REACT_APP_APP_NAME} ("we", "our", or "us") respects your privacy. This
        Privacy Policy describes how we collect, use, and protect your
        information when you use our web application.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Information We Collect</h2>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Google Account Information:</strong> We access your basic Google
          profile information (such as your name and email address) when you sign
          in with your Google account.
        </li>
        <li>
          <strong>Google Calendar Data:</strong> With your explicit permission, we
          access your Google Calendar events to provide core functionality of the
          app, such as displaying or syncing events.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. How We Use Your Information</h2>
      <ul className="list-disc list-inside mb-4">
        <li>To display and manage your calendar events within the app.</li>
        <li>To improve the app experience and functionality.</li>
      </ul>
      <p className="mb-4">
        We do <strong>not</strong> sell, share, or use your data for advertising purposes.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Data Storage & Security</h2>
      <p className="mb-4">
        We follow best practices to securely store and handle any data accessed from
        Google APIs. We comply with Google's Limited Use Policy:
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline ml-1"
        >
          View Policy
        </a>
        .
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Third-party Sharing</h2>
      <p className="mb-4">
        We do not share your personal information or calendar data with third
        parties, except as required by law.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. User Control & Data Deletion</h2>
      <p className="mb-4">
        You can revoke our access at any time through your Google Account settings:
        <a
          href="https://myaccount.google.com/permissions"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline ml-1"
        >
          Manage Access
        </a>
        .
      </p>
      <p className="mb-4">
        You may also contact us at <strong>{process.env.REACT_APP_COMPANY_EMAIL}</strong> to request deletion
        of any data we may store.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Changes to This Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you of
        significant changes by updating the date above or through app notifications.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us:
      </p>
      <ul className="list-inside list-disc mt-4">
        <li>Company: <strong>{process.env.REACT_APP_COMPANY_NAME}</strong></li>
        <li>Email: <strong>{process.env.REACT_APP_COMPANY_EMAIL}</strong></li>
        <li>Website: <strong>{process.env.REACT_APP_COMPANY_WEBSITE}</strong></li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;