import React from 'react';
import { getAsync, postAsync } from '../../_metronic/helpers/httpClient'
import Swal from 'sweetalert2';
import { PRIMARY_COLOR } from '../../_metronic/helpers/colorConstant';
import { useIntl } from 'react-intl';

interface GoogleCalendarAuthProps {
  onAuthSuccess: () => void;
  onSignedOut: () => void;
  isAuthenticated: boolean;
}

const GoogleCalendarAuth: React.FC<GoogleCalendarAuthProps> = ({ onAuthSuccess, onSignedOut, isAuthenticated }) => {
  const intl = useIntl()
  
  const handleGoogleAuth = async () => {
    try {
      const response = await getAsync('/calendar/google/auth-url');
      
      // Open Google OAuth popup
      const popup = window.open(
        response.data.authUrl,
        'Google Calendar Auth',
        'width=600,height=600'
      );

      // Listen for the OAuth callback
      window.addEventListener('message', async (event) => {
        if (event.data.type === 'GOOGLE_CALENDAR_AUTH_SUCCESS') {
          onAuthSuccess();
          popup?.close();
        }
      });
    } catch (error) {
      console.error('Failed to initiate Google Calendar authentication:', error);
    }
  };

  const handleSignOut = async () => {
    Swal.fire({
        title: "",
        text: intl.formatMessage({ id: 'CALENDAR.INFO.POST_SIGNOUT_SENTENCE' }),
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                var response = await postAsync('/calendar/google/sign-out');
                if (response.isSuccess) {
                  onSignedOut();
                }
            } catch (error) {
                console.error('Failed to sign out from Google Calendar:', error);
            }
        }
    });
  }

  return (
    <div className="d-flex justify-content-end mb-3">
      <i 
        className={`ki-duotone ki-google fs-3x mr-5 mb-5 cursor-pointer ${isAuthenticated && 'opacity-50'}`}
        onClick={() => !isAuthenticated ? handleGoogleAuth() : handleSignOut()}>
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
      </i>
    </div>
  );
};

export default GoogleCalendarAuth; 