import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Row, Col } from 'reactstrap';
import { KTCard, KTCardBody, enumDiscountType } from '../../../_metronic/helpers';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const UserPersonalDiscount = ({ 
    user, 
    personalDiscount,
    getPersonalDiscount,
    postPersonalDiscount,
    patchPersonalDiscount 
}) => {
    const intl = useIntl();
    const [discountExists, setDiscountExists] = useState(false);

    useEffect(() => {
        if (user && user.id > 0) {
            getPersonalDiscount(user.id);
        }
    }, [user]);

    useEffect(() => {
        setDiscountExists(personalDiscount && personalDiscount.id > 0);
    }, [personalDiscount]);

    const handleValidSubmit = (_event, values) => {
        const discountData = {
            type: Number(values.type),
            value: Number(values.value)
        };

        if (discountExists) {
            patchPersonalDiscount(user.id, personalDiscount.id, discountData);
        } else {
            postPersonalDiscount(user.id, user.type, discountData);
        }
    };

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    };

    const renderDiscountForm = () => {
        if (user && user.id > 0) {
            return (
                <KTCard className='mt-10'>
                    <div className="card-header">
                        <h3 className="card-title">{intl.formatMessage({ id: 'DISCOUNT.PERSONAL.TITLE' })}</h3>
                    </div>
                    <KTCardBody>
                        <AvForm key={1} onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6}>
                                    <AvField 
                                        type="select" 
                                        name="type" 
                                        label={intl.formatMessage({ id: 'DISCOUNT.TYPE' })} 
                                        validate={validation} 
                                        value={personalDiscount ? personalDiscount.type : ''}
                                    >
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        <option value={enumDiscountType.PercentageBased}>{intl.formatMessage({ id: 'DISCOUNT.TYPE.PERCENTAGE' })}</option>
                                        <option value={enumDiscountType.FixedAmount}>{intl.formatMessage({ id: 'DISCOUNT.TYPE.FIXED' })}</option>
                                    </AvField>
                                </Col>
                                <Col md={6}>
                                    <AvField 
                                        name="value" 
                                        label={intl.formatMessage({ id: 'DISCOUNT.VALUE' })} 
                                        type="number" 
                                        value={personalDiscount ? personalDiscount.value : ''} 
                                        validate={validation} 
                                    />
                                </Col>
                            </Row>
                            <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                        </AvForm>
                    </KTCardBody>
                </KTCard>
            );
        }
        return (<></>);
    };

    return renderDiscountForm();
};

export default UserPersonalDiscount;
