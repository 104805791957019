import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './auth'
import { getAsync } from '../../_metronic/helpers/httpClient'
import { useNavigate } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import { enumPolicies, enumCalendarEventType } from '../../_metronic/helpers'
import GoogleCalendarAuth from './GoogleCalendarAuth';

interface CalendarEvent {
  id: string;
  title: string;
  description?: string;
  start: string;
  end: string;
  allDay: boolean;
  eventIdentifier: number;
  eventSourceId: string;
}

interface CalendarEventsResponse {
  items: CalendarEvent[];
}

const CalendarComponent: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventsResponse>({ items: [] });
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  
  const dateRangeRef = useRef<{start: Date, end: Date} | null>(null);

  const getCalendarEvents = async (startDate?: Date, endDate?: Date) => {
    try {
      // Format dates for API query parameters
      const start = startDate ? startDate.toISOString() : undefined;
      const end = endDate ? endDate.toISOString() : undefined;
      
      // Build query string with date parameters
      let queryParams = '';
      if (start) queryParams += `startDate=${encodeURIComponent(start)}`;
      if (end) queryParams += `${queryParams ? '&' : ''}endDate=${encodeURIComponent(end)}`;
      
      const url = `/calendar-events${queryParams ? `?${queryParams}` : ''}`;
      const response = await getAsync(url);
      
      if (response && response.data) {
        setCalendarEvents(response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Failed to fetch calendar events:', error);
    }
  }
  
  function toUTC(dateString: string) {
    let localDate = new Date(dateString);
    return localDate.toISOString();
  }

  const fetchGoogleCalendarEvents = async (regularEvents: CalendarEventsResponse, startDate?: Date, endDate?: Date) => {
    try {
      // Format dates for API query parameters
      const start = startDate ? startDate.toISOString() : undefined;
      const end = endDate ? endDate.toISOString() : undefined;
      
      // Build query string with date parameters
      let queryParams = '';
      if (start) queryParams += `startDate=${encodeURIComponent(start)}`;
      if (end) queryParams += `${queryParams ? '&' : ''}endDate=${encodeURIComponent(end)}`;
      
      const url = `/calendar/google/events${queryParams ? `?${queryParams}` : ''}`;
      const response = await getAsync(url);
      
      if (response && response.data && response.data.items) {
        const googleEvents = response.data.items.map(event => ({
          ...event,
          start: toUTC(event.start),
          end: toUTC(event.end),
          allDay: event.isAllDay,
          eventIdentifier: enumCalendarEventType.GoogleCalendar,
          eventSourceId: event.htmlLink
        }));
        
        // Filter out Google events before adding new ones
        const filteredEvents = regularEvents.items.filter(event => 
          event.eventIdentifier !== enumCalendarEventType.GoogleCalendar
        );
        
        setCalendarEvents({
          items: [...filteredEvents, ...googleEvents]
        });
      }
    } catch (error) {
      console.error('Failed to fetch Google Calendar events:', error);
    }
  }

  useEffect(() => {
    const checkGoogleAuth = async () => {
      try {
        const response = await getAsync("/calendar/google/status");
        setIsGoogleAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        console.error('Failed to check Google Calendar authentication status:', error);
      }
    }

    if (currentUser && currentUser.policyIds && currentUser.policyIds.some(policy => policy === enumPolicies.CalendarEventRead)) {
      // Just check auth status - calendar events will be loaded when dates are set
      checkGoogleAuth();
    }
  }, [])
  
  useEffect(() => {
    if (isGoogleAuthenticated && dateRangeRef.current) {
      fetchGoogleCalendarEvents(calendarEvents, dateRangeRef.current.start, dateRangeRef.current.end);
    }

    if (!isGoogleAuthenticated)
      filterGoogleCalendarEvents();
  }, [isGoogleAuthenticated])

  const handleGoogleAuthSuccess = async () => {
    setIsGoogleAuthenticated(true);
    if (dateRangeRef.current) {
      await fetchGoogleCalendarEvents(calendarEvents, dateRangeRef.current.start, dateRangeRef.current.end);
    }
  }

  const handleGoogleSignOut = () => {
    setIsGoogleAuthenticated(false);
  }
  
  // Format event date/time based on whether it's an all-day event or not
  const formatEventDateTime = (event: any) => {
    if (!event.start) return '';
    
    // Check if it's an all-day event
    const isAllDay = event.allDay;
    
    let dateTimeStr = '';
    
    if (isAllDay) {
      // For all-day events, only show the date
      const options: Intl.DateTimeFormatOptions = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      };
      
      dateTimeStr = event.start.toLocaleDateString(undefined, options);
      
      // If there's an end date and it's different from start date
      if (event.end && event.end.toDateString() !== event.start.toDateString()) {
        dateTimeStr += ` - ${event.end.toLocaleDateString(undefined, options)}`;
      }
    } else {
      // For regular events, show date and time in HH:MM format (no seconds)
      const dateOptions: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
      
      dateTimeStr = event.start.toLocaleString(undefined, dateOptions);
      
      if (event.end) {
        dateTimeStr += ` - ${event.end.toLocaleString(undefined, dateOptions)}`;
      }
    }
    
    return dateTimeStr;
  }

  const ToFullCalendarEvent = (calendarEvents) => {
    if (calendarEvents.items == undefined)
      return;

    const ToClassName = (eventIdentifier) => {
      switch (eventIdentifier) {
        case enumCalendarEventType.QuotePreparation:
          return "fc-event-success"
        case enumCalendarEventType.OrderPreparation:
          return "fc-event-solid-primary fc-event-light"
        case enumCalendarEventType.GoogleCalendar:
          return "fc-event-solid-info-2 fc-event-light"
        case enumCalendarEventType.OrderInstallation:
        default:
          return "fc-event-solid-info fc-event-light"
      }
    }

    const ToIsoFormat = (date) => {
      if (date) {
        const dateClass = new Date(date);
        return dateClass.toISOString()
      } else {
        return undefined
      }
    }

    const calendarEventDtos = calendarEvents.items.map(e => ({
      title: e.title,
      description: e.description,
      start: ToIsoFormat(e.start),
      end: ToIsoFormat(e.end),
      allDay: e.allDay,
      className: ToClassName(e.eventIdentifier),
      extendedProps: {
        eventIdentifier: e.eventIdentifier,
        eventSourceId: e.eventSourceId
      }
    }))
    return calendarEventDtos
  }

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    const eventIdentifier = clickInfo.event.extendedProps.eventIdentifier;
    const eventSourceId = clickInfo.event.extendedProps.eventSourceId

    switch (eventIdentifier) {
      case enumCalendarEventType.QuotePreparation:
        navigate(`/order-management/quotes/${eventSourceId}`);
        break;
      case enumCalendarEventType.OrderPreparation:
      case enumCalendarEventType.OrderInstallation:
        navigate(`/order-management/orders/${eventSourceId}`);
        break;
      case enumCalendarEventType.GoogleCalendar:
        const htmlLink = eventSourceId;
        window.open(htmlLink, '_blank');
        break;
      default:
        console.log('Default event handling');
    }
  }

  const filterGoogleCalendarEvents = () => {
    const filteredEvents = calendarEvents.items.filter(event => 
      event.eventIdentifier !== enumCalendarEventType.GoogleCalendar
    );
    
    setCalendarEvents({
      items: [...filteredEvents]
    });
  }

  return (
    <div>
      <GoogleCalendarAuth 
        onAuthSuccess={handleGoogleAuthSuccess}
        onSignedOut={handleGoogleSignOut}
        isAuthenticated={isGoogleAuthenticated}
      />          
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        }}
        height={800}
        contentHeight={780}
        aspectRatio={3}
        nowIndicator={true}
        now={`${new Date().toISOString()}`}
        editable={true}
        dayMaxEvents={true}
        navLinks={true}
        firstDay={1}
        events={calendarEvents && ToFullCalendarEvent(calendarEvents)}
        eventClick={handleEventClick}
        datesSet={async (dateInfo) => {
          if (dateInfo?.start.getTime() !== dateRangeRef.current?.start.getTime() ||
            dateInfo?.end.getTime() !== dateRangeRef.current?.end.getTime()
          ) {
            // filterGoogleCalendarEvents();
            setCalendarEvents({
              items: []
            });
            dateRangeRef.current = {
              start: dateInfo.start,
              end: dateInfo.end
            };

            // Fetch both regular calendar events and Google Calendar events with the same date range
            const regularEvents = await getCalendarEvents(dateInfo.start, dateInfo.end);
            
            if (isGoogleAuthenticated) {
              fetchGoogleCalendarEvents(regularEvents, dateInfo.start, dateInfo.end);
            }
          }
        }}
        eventContent={(eventInfo) => {
          const description = eventInfo.event.extendedProps.description;
          return (
            <div 
              title={
                `${eventInfo.event.title}\n` +
                `${formatEventDateTime(eventInfo.event)}` +
                `${description ? '\n\n' + description : ''}`
              } // Show title, date/time, and description in tooltip
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <div>{eventInfo.event.title}</div>
              {/* Only show description in day/week views where there's more space */}
              {description && ['timeGridDay', 'timeGridWeek'].includes(eventInfo.view.type) && 
                <div className="fc-description">{description}</div>
              }
            </div>
          );
        }}
        locales={allLocales}
        locale={currentUser && currentUser.languageCode}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // Forces 24-hour format
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
      />
    </div>
  );
};

export default CalendarComponent;