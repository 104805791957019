/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../app/modules/auth'
import { PageTitle } from '../../../_metronic/layout/core'
import { XYGraphWidget, PieChartGraphWidget } from '../../../_metronic/partials/widgets';
import { getAsync } from '../../../_metronic/helpers/httpClient'
import { Row, Col, Card, CardTitle, CardHeader, CardBody } from 'reactstrap';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import { enumPolicies } from '../../../_metronic/helpers'

import CalendarComponent from '../../../app/modules/CalendarComponent';

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const { currentUser } = useAuth();
  const [leadsMetric, setLeadMetric] = useState<any>('');
  const [newLeadDataItems, setItems] = useState([])
  const [ordersMetric, setOrdersMetric] = useState<any>('');

  useEffect(() => {
    const leadsMetric = async () => {
      const response = await getAsync("/dashboards/leads-metrics")
      if (response && response.data) {
        setLeadMetric(response.data)
        setItems(response.data.newLeadsCountPerCampaign)
      }
    }

    const ordersMetric = async () => {
      const response = await getAsync("/dashboards/orders-metrics")
      if (response && response.data) {
        setOrdersMetric(response.data)
      }
    }

    if (currentUser && currentUser.policyIds && currentUser.policyIds.some(policy => policy === enumPolicies.AdsLeadEdit)) {
      leadsMetric();
    }
    ordersMetric();
  }, [])

  const MetricCard = ({ title, subtitle, value, color }) => (
    <Card className="h-100 shadow-sm">
      <CardBody>
        <CardTitle tag="h5" className="text-muted">{title}</CardTitle>
        <p className="text-muted small">{subtitle}</p>
        <h3 className="mt-3" style={{ color }}>{value}</h3>
      </CardBody>
    </Card>
  );

  const renderOrderMetric = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            {intl.formatMessage({ id: 'ORDERS.METRICS.HEADER' })}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col md={3}>
              <MetricCard
                title={intl.formatMessage({ id: 'ORDERS.METRICS.TODAY' })}
                subtitle={intl.formatMessage({ id: 'ORDERS.METRIC.COUNT' })}
                value={ordersMetric.todayOrderCount}
                color="#4CAF50"
              />
            </Col>
            <PolicyChecker policies={[enumPolicies.OrderDealer, enumPolicies.OrderAdmin]}>
              <Col md={3}>
                <MetricCard
                  title={intl.formatMessage({ id: 'ORDERS.METRICS.TODAY' })}
                  subtitle={intl.formatMessage({ id: 'ORDERS.METRIC.PRICE' })}
                  value={`€ ${ordersMetric.todayTotalPrice}`}
                  color="#2196F3"
                />
              </Col>
            </PolicyChecker>
            <Col md={3}>
              <MetricCard
                title={intl.formatMessage({ id: 'ORDERS.METRICS.14_DAYS' })}
                subtitle={intl.formatMessage({ id: 'ORDERS.METRIC.COUNT' })}
                value={ordersMetric.orderCount}
                color="#4CAF50"
              />
            </Col>
            <PolicyChecker policies={[enumPolicies.OrderDealer, enumPolicies.OrderAdmin]}>
              <Col md={3}>
                <MetricCard
                  title={intl.formatMessage({ id: 'ORDERS.METRICS.14_DAYS' })}
                  subtitle={intl.formatMessage({ id: 'ORDERS.METRIC.PRICE' })}
                  value={`€ ${ordersMetric.totalPrice}`}
                  color="#2196F3"
                />
              </Col>
            </PolicyChecker>
          </Row>
          <Row>
            <Col md={6}>
              {
                ordersMetric && ordersMetric?.dateBasedOrder?.length > 0 && <XYGraphWidget name={intl.formatMessage({ id: 'ORDERS.METRIC.DATEBASED_ORDERS' })} chartData={ordersMetric.dateBasedOrder} />
              }
            </Col>
            <Col md={6}>
              {
                ordersMetric && ordersMetric?.statusBasedOrders?.length > 0 && <PieChartGraphWidget name={intl.formatMessage({ id: 'ORDERS.METRIC.STATUSBASED_ORDERS' })} chartData={ordersMetric.statusBasedOrders} />
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  const renderLeadsMetric = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            {intl.formatMessage({ id: 'LEADS.METRICS.HEADER' })}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            {newLeadDataItems && newLeadDataItems.map(function (item) {
              return (
                <Col md={3}>
                  <MetricCard
                    title={item['title']}
                    subtitle={item['subtitle']}
                    value={item['count']}
                    color="#4CAF50"
                  />
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md={6}>
              {
                leadsMetric && leadsMetric?.dateBasedCampaigns?.length > 0 && <XYGraphWidget name={intl.formatMessage({ id: 'LEADS.METRIC.DATEBASED_CAMPAIGNTYPES' })} chartData={leadsMetric.dateBasedCampaigns} />
              }
            </Col>
            <Col md={6}>
              {
                leadsMetric && leadsMetric?.statusBasedCampaigns?.length > 0 && <XYGraphWidget name={intl.formatMessage({ id: 'LEADS.METRIC.STATUSBASED_CAMPAIGNTYPES' })} chartData={leadsMetric.statusBasedCampaigns} />
              }
            </Col>
          </Row>
        </CardBody>
      </Card>

    )
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>


      <PolicyChecker policies={[enumPolicies.CalendarEventRead]}>
        <Card>
          <CardHeader>
            <CardTitle>
              {intl.formatMessage({ id: 'CALENDAR.DASHBOARD.HEADER' })}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CalendarComponent />
          </CardBody>
        </Card>
        <Row className='mb-10'></Row>
      </PolicyChecker>

      <PolicyChecker policies={[enumPolicies.OrderRead, enumPolicies.OrderEdit, enumPolicies.OrderWrite]}>
        {renderOrderMetric()}
        <Row className='mb-10'></Row>
      </PolicyChecker>

      <PolicyChecker policies={[enumPolicies.AdsLeadEdit]}>
        {renderLeadsMetric()}
        <Row className='mb-10'></Row>
      </PolicyChecker>
    </>
  )
}

export { DashboardWrapper }
