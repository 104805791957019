// @flow
import actionTypes from './signatureConstants';

const INIT_STATE = {
    signature: null,
    loading: false,
    error: null,
    success: null
};

const Signature = (state = INIT_STATE, action) => {
    switch (action.type) {

        case actionTypes.CLEAR_STATE:
            return {
                ...INIT_STATE
            };

        case actionTypes.POST_SIGNATURE:
        case actionTypes.GET_SIGNATURE:
        case actionTypes.VERIFY_SIGNATURE:
        case actionTypes.PATCH_SIGNATURE:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };

        case actionTypes.POST_SIGNATURE_SUCCESS:
        case actionTypes.GET_SIGNATURE_SUCCESS:
        case actionTypes.VERIFY_SIGNATURE_SUCCESS:
        case actionTypes.PATCH_SIGNATURE_SUCCESS:
            return {
                ...state,
                signature: action.payload.data,
                loading: false,
                error: null,
                success: `${action.type}_SUCCESS`
            };

        case actionTypes.POST_SIGNATURE_FAILED:
        case actionTypes.GET_SIGNATURE_FAILED:
        case actionTypes.VERIFY_SIGNATURE_FAILED:
        case actionTypes.PATCH_SIGNATURE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: null
            };

        default:
            return state;
    }
};

export default Signature;
