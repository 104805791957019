// @flow
import types from './userConstants';

const INIT_STATE = {
    userList: [],
    user: {},
    newAvatar: null,
    contact: {},
    personalDiscount: null,
    loading: false,
    success: null,
    error: null
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_USERS:
            return {
                ...state,
                loading: true
            };
        case types.GET_USERS_SUCCESS:

            return {
                ...state,
                userList: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_USERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Change Status
        case types.PATCH_USER_STATUS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_USER_STATUS_SUCCESS:

            var list = [...state.userList].map((item, index) => {
                if (item.id == action.payload.id) {
                    return {
                        ...item,
                        isActive: action.payload.isActive
                    }
                }

                return item;
            });

            return {
                ...state,
                userList: list,
                loading: false,
                success: 'USER.STATUS.UPDATE.SUCESS',
                error: null
            };
        case types.PATCH_USER_STATUS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Change Status

        //Get User
        case types.GET_USER:
            return {
                ...state,
                loading: true
            };
        case types.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get User

        //Post User
        case types.POST_USER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                loading: false,
                success: 'USER.CREATE.SUCCESS',
                error: null
            };
        case types.POST_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post User

        //Put User
        case types.PATCH_USER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'USER.UPDATE.SUCCESS',
                error: null
            };
        case types.PATCH_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Put User

        //Signed Url
        case types.GET_NEW_AVATAR:
            return {
                ...state,
                newAvatar: action.payload.path,
                loading: false,
                error: null
            };
        //Signed Url


        //Get Contact
        case types.GET_CONTACT:
            return {
                ...state,
                loading: true
            };
        case types.GET_CONTACT_SUCCESS:
            return {
                ...state,
                contact: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_CONTACT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Contact

        //Post Contact
        case types.POST_CONTACT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'USER.CONTACT.CREATE.SUCCESS',
                error: null
            };
        case types.POST_CONTACT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Contact

        //Put Contact
        case types.PUT_CONTACT:
            return {
                ...state,
                success: null,
            loading: true
            };
        case types.PUT_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'USER.CONTACT.UPDATE.SUCCESS',
                error: null
            };
        case types.PUT_CONTACT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Put Contact
        
        //Get Policies
        case types.GET_POLICIES:
            return {
                ...state,
                policies: action.payload.data.items,
                //loading: false,
                error: null
            };
        //Get Policies

        //Get Personal Discount
        case types.GET_PERSONAL_DISCOUNT:
            return {
                ...state,
                loading: true
            };
        case types.GET_PERSONAL_DISCOUNT_SUCCESS:
            return {
                ...state,
                personalDiscount: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_PERSONAL_DISCOUNT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Personal Discount

        //Post Personal Discount
        case types.POST_PERSONAL_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_PERSONAL_DISCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'DISCOUNT.PERSONAL.CREATE.SUCCESS',
                error: null
            };
        case types.POST_PERSONAL_DISCOUNT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Personal Discount

        //Patch Personal Discount
        case types.PATCH_PERSONAL_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_PERSONAL_DISCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'DISCOUNT.PERSONAL.UPDATE.SUCCESS',
                error: null
            };
        case types.PATCH_PERSONAL_DISCOUNT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Patch Personal Discount

        default:
            return state;
    }
};

export default User;
