const DocumentPrint = (props) => {
    const { document, component, intl, type } = props;

    if (document === undefined || document === null || document.id == 0) {
        return null;
    }

    const cdnDomain = process.env.REACT_APP_CDN_DOMAIN

    const renderHtml = (text) => {
        if (text === null || text === undefined) {
            return <></>;
        }
        return (
            <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }}></div>
        )
    }

    const renderTransactions = () => {
        if (type === 'invoice' && document.paidAmount > 0 && document.paidAmount < document.totalPriceIncVat) {
            return (
                <>
                    <tr>
                        <td colSpan={2}></td>
                        <td>
                            {intl.formatMessage({ id: 'INVOICE.ALREADY_PAID' })}:
                        </td>
                        <td>
                            € {document.paidAmount.toFixed(2)}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}></td>
                        <td>
                            {intl.formatMessage({ id: 'INVOICE.OUTSTANDING' })}:
                        </td>
                        <td>
                            € {(document.totalPriceIncVat - document.paidAmount).toFixed(2)}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>
                </>
            )
        }
        return null;
    }

    var pageStyle = `
        @page {
            size: A4;
            margin: 20mm;
        }
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .container {
            width: 210mm;
            padding: 20mm;
            margin: auto;
        }
        .header, .footer {
            text-align: left;
            margin-bottom: 20px;
        }
        .logo {
            margin-bottom: 20px;
        }
        .client-info, .company-info {
            height: 150px;
            width: 48%;
            display: inline-block;
            vertical-align: top;
            margin-top: 30px;
        }
        .company-info {
            margin-right: 4%;
        }
        .document-title {
            font-size: 22px;
            margin-bottom: 10px;
        }
        .document-details {
            display: inline-flex;
        }
        .document-details p {
            margin-right: 50px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            padding: 8px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }
        th {
            background-color: #f2f2f2;
        }
        .total-section td {
            border: none;
        }
        .border-top-solid {
            border-top: 1px solid black !important;
        }
    `;

    var componentStyle = `
        .client-info, .company-info {
            height: 150px;
            margin-top: 30px;
        }
        .document-title {
            font-size: 22px;
            margin-bottom: 10px;
        }
        .document-details {
            display: inline-flex;
        }
        .document-details p {
            margin-right: 50px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            padding: 8px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }
        th {
            background-color: #f2f2f2;
        }
        .total-section td {
            border: none;
        }
        .border-top-solid {
            border-top: 1px solid black !important;
        }
    `;

    const renderDetails = () => {
        if (type === 'invoice') {
            return (
                <div className="document-details">
                    <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_NUMBER'})}</strong><br/> {document.invoiceNr}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_DATE'})}</strong><br/> {document.invoiceDate}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_EXPIRE_DATE'})}</strong><br/> {document.expiryDate}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_RELATIONSHIPNR'})}</strong><br/> {document.relationshipNr}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_ORDER_NUMBER'})}</strong><br/> {document.orderId}</p>
                </div>
            );
        } else {
            return (
                <div className="document-details">
                    <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_NUMBER'})}</strong><br/> {document.quoteNr}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_DATE'})}</strong><br/> {document.quoteDate}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_EXPIRE_DATE'})}</strong><br/> {document.expiryDate}</p>
                    <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_RELATIONSHIPNR'})}</strong><br/> {document.relationshipNr}</p>
                </div>
            );
        }
    }

    const renderItems = () => {
        const items = type === 'invoice' ? document.invoiceItems : document.quoteItems;
        return items?.map((item, index) => (
            <tr key={`table_row_${index}`}>
                <td>{item.quantity}</td>
                <td style={{ wordBreak: "break-all", width: "300px" }}>{renderHtml(item.description)}</td>
                <td>€{item.unitPrice}</td>
                <td>€{item.totalPrice}</td>
                <td>{!item.isDiscountItem ? `${item.vat}%` : ``}</td>
            </tr>
        ));
    }

    return (
        <>
            <title>&nbsp;</title>
            <style>
                {component ? componentStyle : pageStyle}
            </style>
            <body style={{ minWidth: "700px" }}>
                <div className="container">
                    <div className="logo">
                        <img src={`${cdnDomain}/company/logo.png`} width={300} alt="logo"/>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <div className="company-info">
                            {renderHtml(document.companyDetailsString)}
                        </div>
                        <div className="client-info">
                            {renderHtml(document.customContact)}
                        </div>
                    </div>

                    <div className="document-title">
                        {intl.formatMessage({ id: type === 'invoice' ? 'PRINT.INVOICE' : 'PRINT.QUOTE' })}
                    </div>

                    {renderDetails()}

                    <table>
                        <thead>
                            <tr>
                                <th>{intl.formatMessage({id: `PRINT.${type.toUpperCase()}_Q`})}</th>
                                <th>{intl.formatMessage({id: `PRINT.${type.toUpperCase()}_DESCRIPTION`})}</th>
                                <th>{intl.formatMessage({id: `PRINT.${type.toUpperCase()}_PRICE`})}</th>
                                <th>{intl.formatMessage({id: `PRINT.${type.toUpperCase()}_AMOUNT`})}</th>
                                <th>{intl.formatMessage({id: `PRINT.${type.toUpperCase()}_VAT`})}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderItems()}
                        </tbody>
                        <tfoot className="total-section">
                            <tr>
                                <td colSpan={2}></td>
                                <td>{intl.formatMessage({id: 'PRINT.TOTAL_EXCLUSIEF_BTW'})}</td>
                                <td>€{document.totalPrice}</td>
                                <td colSpan={1}></td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td>{intl.formatMessage({id: 'PRINT.TOTAL_BTW'})}</td>
                                <td>€{document.vatPrice}</td>
                                <td colSpan={1}></td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td colSpan={3}></td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td className="bold border-top-solid">{intl.formatMessage({id: 'PRINT.TOTAL_INC_BTW'})}</td>
                                <td className='border-top-solid'>€{document.totalPriceIncVat}</td>
                                <td colSpan={1} className="border-top-solid"></td>
                            </tr>
                            {renderTransactions()}
                        </tfoot>
                    </table>

                    <div className="footer small-text">
                        <p> </p>
                    </div>
                </div>
            </body>
        </>
    );
};

export default DocumentPrint; 