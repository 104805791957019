/* SIGNATURE */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'SIGNATURE/CLEAR_STATE',
    
    POST_SIGNATURE: 'SIGNATURE/POST_SIGNATURE',
    POST_SIGNATURE_SUCCESS: 'SIGNATURE/POST_SIGNATURE_SUCCESS',
    POST_SIGNATURE_FAILED: 'SIGNATURE/POST_SIGNATURE_FAILED',

    GET_SIGNATURE: 'SIGNATURE/GET_SIGNATURE',
    GET_SIGNATURE_SUCCESS: 'SIGNATURE/GET_SIGNATURE_SUCCESS',
    GET_SIGNATURE_FAILED: 'SIGNATURE/GET_SIGNATURE_FAILED',

    VERIFY_SIGNATURE: 'SIGNATURE/VERIFY_SIGNATURE',
    VERIFY_SIGNATURE_SUCCESS: 'SIGNATURE/VERIFY_SIGNATURE_SUCCESS',
    VERIFY_SIGNATURE_FAILED: 'SIGNATURE/VERIFY_SIGNATURE_FAILED',

    PATCH_SIGNATURE: 'SIGNATURE/PATCH_SIGNATURE',
    PATCH_SIGNATURE_SUCCESS: 'SIGNATURE/PATCH_SIGNATURE_SUCCESS',
    PATCH_SIGNATURE_FAILED: 'SIGNATURE/PATCH_SIGNATURE_FAILED'
});

export default actionTypes;
